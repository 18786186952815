export const paths = {
  index: '/',
  checkout: '/checkout',
  contact: '/contact',
  pricing: '/pricing',
  auth: {
    auth0: {
      callback: '/auth/auth0/callback',
      login: '/auth/auth0/login'
    },
    jwt: {
      login: '/auth/jwt/login',
      register: '/auth/jwt/register'
    },
    firebase: {
      login: '/auth/firebase/login',
      register: '/auth/firebase/register'
    },
    amplify: {
      confirmRegister: '/auth/amplify/confirm-register',
      forgotPassword: '/auth/amplify/forgot-password',
      login: '/auth/amplify/login',
      register: '/auth/amplify/register',
      resetPassword: '/auth/amplify/reset-password'
    }
  },
  authDemo: {
    forgotPassword: {
      classic: '/auth-demo/forgot-password/classic',
      modern: '/auth-demo/forgot-password/modern'
    },
    login: {
      classic: '/auth-demo/login/classic',
      modern: '/auth-demo/login/modern'
    },
    register: {
      classic: '/auth-demo/register/classic',
      modern: '/auth-demo/register/modern'
    },
    resetPassword: {
      classic: '/auth-demo/reset-password/classic',
      modern: '/auth-demo/reset-password/modern'
    },
    verifyCode: {
      classic: '/auth-demo/verify-code/classic',
      modern: '/auth-demo/verify-code/modern'
    }
  },
  dashboard: {
    index: '/dashboard',
    academy: {
      index: '/dashboard/academy',
      courseDetails: '/dashboard/academy/courses/:courseId'
    },
    account: '/dashboard/account',
    analytics: '/dashboard/analytics',
    blank: '/dashboard/blank',
    blog: {
      index: '/dashboard/blog',
      postDetails: '/dashboard/blog/:postId',
      postCreate: '/dashboard/blog/create'
    },
    calendar: '/dashboard/calendar',
    chat: '/dashboard/chat',
    crypto: '/dashboard/crypto',
    customers: {
      index: '/dashboard/customers',
      details: '/dashboard/customers/:customerId',
      edit: '/dashboard/customers/:customerId/edit'
    },
    ecommerce: '/dashboard/ecommerce',
    fileManager: '/dashboard/file-manager',
    invoices: {
      index: '/dashboard/invoices',
      details: '/dashboard/invoices/:orderId'
    },
    jobs: {
      index: '/dashboard/jobs',
      create: '/dashboard/jobs/create',
      companies: {
        details: '/dashboard/jobs/companies/:companyId'
      }
    },
    kanban: '/dashboard/kanban',
    logistics: {
      index: '/dashboard/logistics',
      fleet: '/dashboard/logistics/fleet'
    },
    mail: '/dashboard/mail',
    orders: {
      index: '/dashboard/orders',
      details: '/dashboard/orders/:orderId'
    },
    products: {
      index: '/dashboard/products',
      create: '/dashboard/products/create'
    },
    lines: {
      index: '/dashboard/lines',
      create: '/dashboard/lines/create'
    },
    records: {
      index: '/dashboard/records',
      create: '/dashboard/records/create'
    },
    recordslist: {
      index: '/dashboard/recordslist',
      details: '/dashboard/recordslist/:customerId',
      create: '/dashboard/records/create'
    },
    calltransfer: {
      index: '/dashboard/calltransfer',
      details: '/dashboard/calltransfer/:customerId',
      create: '/dashboard/calltransfer/create'
    },
    tzintuks: {
      index: '/dashboard/tzintuks',
      details: '/dashboard/tzintuks/:customerId',
      create: '/dashboard/tzintuks/create'
    },
    ivr: {
      index: '/dashboard/ivr',
      details: '/dashboard/ivr/:customerId',
      create: '/dashboard/ivr/create'
    },
    trivia: {
      index: '/dashboard/trivia',
      details: '/dashboard/trivia/:customerId',
      create: '/dashboard/trivia/create'
    },
    conferences: {
      index: '/dashboard/conferences',
      details: '/dashboard/conferences/:customerId',
      create: '/dashboard/conferences/create'
    },
    conferencesmock: {
      index: '/dashboard/conferencesmock',
      details: '/dashboard/conferencesmock/:customerId',
      create: '/dashboard/conferencesmock/create'
    },
    messages: {
      index: '/dashboard/messages',
      create: '/dashboard/messages/create'
    },
    messages2: {
      index: '/dashboard/messages2',
      create: '/dashboard/messages2/create'
    },
    messages3: {
      index: '/dashboard/messages3',
      create: '/dashboard/messages3/create'
    },
    messages4: {
      index: '/dashboard/messages4',
      create: '/dashboard/messages4/create'
    },
    reportdakot: {
      index: '/dashboard/reportdakot',
      create: '/dashboard/messages/create'
    },
    reportrecords: {
      index: '/dashboard/reportrecords',
      create: '/dashboard/messages/create'
    },
    events: {
      index: '/dashboard/events',
      create: '/dashboard/messages/create'
    },
    seker: {
      index: '/dashboard/seker',
      create: '/dashboard/seker/create'
    },
    social: {
      index: '/dashboard/social',
      profile: '/dashboard/social/profile',
      feed: '/dashboard/social/feed'
    }
  },
  components: {
    index: '/components',
    dataDisplay: {
      detailLists: '/components/data-display/detail-lists',
      tables: '/components/data-display/tables',
      quickStats: '/components/data-display/quick-stats'
    },
    lists: {
      groupedLists: '/components/lists/grouped-lists',
      gridLists: '/components/lists/grid-lists'
    },
    forms: '/components/forms',
    modals: '/components/modals',
    charts: '/components/charts',
    buttons: '/components/buttons',
    typography: '/components/typography',
    colors: '/components/colors',
    inputs: '/components/inputs'
  },
  docs: 'https://material-kit-pro-react-docs.devias.io',
  notAuthorized: '/401',
  notFound: '/404',
  serverError: '/500'
};
